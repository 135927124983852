.categories {
    font-size: $font-size-lg;
    line-height: 1;

    a {
        display: inline-block;
        padding: 5px 0;
        color: $brand-primary;
        transition: all 0.2s;

        &:hover {
            padding-bottom: 3px;
        }
    }

    &--list {

        a {
            text-decoration: underline;
        }
    }
}
