.tab {
  overflow: hidden;
  border: 1px solid #2e69ff;
  border-radius: 27px;
  display: inline-flex;
}

.tab-container {
  text-align: center;
}

.tab button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 35px;
  font-weight: bold;
  border-radius: 27px;
  transition: 0.3s;
}

//.tab button:hover {
//  background-color: #ddd;
//}

.tab button.active {
  background-color: #2e69ff;
  color: white;
}

.tab-content {
  display: none;
  border-top: none;
  animation: fadeEffect 1s;
}

@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

.country-stats-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.country-stats-list-item {
  padding-left: 1rem;
  padding-right: 1rem;
}