.notification-drawer {
    position: absolute;
    top: 100%;
    right: -50px;
    width: 300px;
    margin-top: 12px;
    background: #fff;
    box-shadow: -13px 13px 20px rgba(0, 0, 0, 0.1);
    transform: translate(0, -10px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;

    &:after {
        position: absolute;
        content: '';
        top: -16px;
        right: 50px;
        transform: translate(-50%, 0);
        border: 8px solid transparent;
        border-bottom-color: #fff;
    }

    &.is-open {
        transform: translate(0, 0);
        opacity: 1;
        visibility: visible;
    }

    .scroll-pane {
        max-height: 50vh;
        overflow: auto;
    }
}

.notifications-empty {
    padding: 40px 20px;
    text-align: center;
}

.notification-count {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    height: 14px;
    min-width: 14px;
    padding-top: 1px;
    padding-right: 4px;
    padding-left: 3px;
    line-height: 14px;
    font-size: 9px;
    font-weight: 600;
    text-align: center;
    border-radius: 7px;
    background-color: #FF6209;
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.15s;

    &.has-notifications {
        transform: scale(1);
        opacity: 1;
    }
}

.notifications {

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        position: relative;
        float: none;
        padding: 16px 16px 16px 54px;
        border-bottom: 1px solid rgba(#979797, 0.2);

        &:last-child {
            border-bottom: none;
        }
    }

    i {
        position: absolute;
        top: 50%;
        left: 12px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        font-size: 14px;
        text-align: center;
        color: #fff;
        background-color: $brand-secondary;
        transform: translate(0, -50%);
    }

    .icon-comment {
        font-size: 12px;
    }

    a {
        display: inline-block;
        padding: 0;
        line-height: 1;
        text-transform: none;
        font-weight: 600;
        color: $brand-primary;
    }

    .notificaton__content {
        margin-top: 4px;
        font-weight: normal;
        font-size: 13px;
    }

    .notificaton__timestamp {
        margin-top: 3px;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: normal;
        color: #BCBCBC;
    }

    .is-read {
        background: #F7F7F7;

        i {
            background-color: #737373;
        }
    }
}
