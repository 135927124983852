.newsletter {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('./img/background-6.svg');
    text-align: center;
    color: #fff;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 0;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate3d(-50%, -50%, 0);
        }
    }

    &__inner {
        max-width: 580px;
        margin: 0 auto;
    }

    &__title {
        margin: 0 0 20px;
        font-weight: 500;
        line-height: (43/30);
        font-size: 24px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 40px;
            font-size: 30px;
        }
    }

    &__form {
        position: relative;
        max-width: 420px;
        margin: 20px auto 0;

        @include media-breakpoint-up(sm) {
            margin-top: 50px;
        }
    }

    &__field {
        transition: all 0.3s;
    }

    &__success {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        transform: translate(0, 20px);
    }

    &--success {

        .newsletter__field {
            opacity: 0;
            visibility: hidden;
            transform: translate(0, -20px);
        }

        .newsletter__success {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
            transition-delay: 0.25s;
        }
    }
}
