.footer {
    padding: 30px 0;
    background: #fff;
    text-align: center;

    @include media-breakpoint-up(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-up(md) {
        padding: 80px 0;
        text-align: left;
    }

    &__logo,
    &__content,
    &__nav,
    &__socials {
        @include make-col-ready();
        @include make-col(12);
    }

    &__logo {
        @include media-breakpoint-down(xs) {
            display: none;
        }

        @include media-breakpoint-up(md) {
            @include make-col(12);
        }

        @include media-breakpoint-up(xl) {
            @include make-col(2);
        }
    }

    .logo {
        display: inline-block;
        margin-bottom: 20px;
    }

    &__content {
        font-size: 14px;

        @include media-breakpoint-up(md) {
            padding-right: 60px;
            @include make-col(4);
        }

        @include media-breakpoint-up(xl) {
            @include make-col(3);
        }
    }

    &__nav {
        @include media-breakpoint-up(md) {
            @include make-col(8);
        }

        @include media-breakpoint-up(xl) {
            @include make-col(4);
        }
    }

    &__socials {
        @include media-breakpoint-up(md) {
            @include make-col(12);
        }

        @include media-breakpoint-up(xl) {
            @include make-col(2);
            float: right;
        }
    }
}
