.page--idea {
}

.page--my-ideas {
}

.page--submit-your-idea {
  background: $brand-primary;

  .section.newsletter {
    display: none;
  }
}

.page--idea-submitted {
  padding-top: 40px;
  background: $brand-primary;

  @include media-breakpoint-up(lg) {
    padding-top: 110px;
  }

  .section--submitted img {
    max-width: 100%;
    margin: 0 auto 40px;
  }
}

.page--login,
.page--register,
.page--forgot-password,
.page--reset-password,
.page--unsubscribe,
.page--auth {
  background: $brand-primary;

  .section.newsletter {
    display: none;
  }

  .main {
    padding: 120px 20px 120px;

    @include media-breakpoint-up(lg) {
      min-height: 100vh;
      padding: 160px 20px;
    }
  }
}

.page--messages_compose,
.page--messages_read {
  .card-col-main {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.page--contact-us,
.page--contact {
  padding-top: 200px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  background-color: $brand-primary;
  background-image: url('./img/background-5.svg');

  .newsletter {
    background-image: none;
    background-color: #2757F0;
  }
}

.page--about {
  .section__editable {

    h2 {
      margin: 10px 0 20px 0;
    }

    h3 {
      // Part X headings
      margin: 80px 0 10px 0;
      color: $body-color;
      font-size: 20px;
      text-transform: uppercase;
    }

    h4 {
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 24px;
    }
  }
}

.page--terms-use {
  .section__editable {
    h2 {
      font-weight: 500;
    }
  }
}

.page--site-stats {
  .stats-container {
    margin-top: 25px;

    h3 {
      font-size: 20px;
    }

    .total-registered {
      font-weight: bold;
    }

    ul {
      list-style-type: none;
    }

    li {
      margin-bottom: 10px;
    }
  }


}


.password-reset-text-container {
  padding: 30px 20px;
  min-height: 230px;
}