/**
 * Ideas styles
 */

.idea {
  position: relative;

  .flag-icon {
    position: relative;
    top: -1px;
    margin-right: 3px;
  }

  .small-top-idea-badge {
    height: 35px;
    margin-top: -18px;
  }

  &__rank {
    display: inline-block;
    margin-bottom: 15px;
    padding: 0 8px;
    min-width: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: (38px / 2);
    font-size: 14px;
    font-weight: 600;
    background: $brand-primary;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
    color: #fff;
  }

  &__title {
    margin: 0 0 10px;
    font-size: 24px;
    line-height: (30 / 24);
    font-weight: 600;
    color: $brand-primary;
  }

  &__meta {
    margin-top: 3px;
    font-size: 13px;

    &, & a {
      color: rgba(0, 0, 0, .5);
    }
  }

  &__stats {
    max-width: 80%;
    margin-top: 12px;
    line-height: 1.75;
    font-size: 13px;
    color: $brand-gray-dark;
  }

  &__stat {
    margin-right: 10px;
    white-space: nowrap;

    i {
      margin-right: 2px;
    }

    .icon-tick {
      font-size: 12px;
    }

    .icon-comment {
      font-size: 11px;
    }
  }

  &__report,
  &__delete {
    font-size: 13px;
    line-height: (21 / 13);

    &, & a {
      color: rgba($brand-gray, .5);
    }
  }

  &__status {
    margin-bottom: 20px;
    color: $brand-gray;
    font-size: 0.875rem;
    font-weight: 500;
  }

  /**
   * Sideabar metrics
   */

  .metrics {
    margin-top: 25px;
    padding: 25px 0 0;
    border-top: 1px solid rgba($brand-gray, .4);
  }

  .metric {
    @extend .clearfix;
    margin: 0 0 10px;
    font-size: $font-size-lg;
    line-height: (29 / 18);
    color: $brand-gray-dark;
  }

  .metric__label {
    float: left;
    width: 120px;
    font-weight: 600;
  }

  .metric__value {

  }

  .result {

    &__label {
      font-size: 14px;
      line-height: 36px;
      vertical-align: top;
      color: $brand-gray;
    }

    &__value {
      margin-right: 10px;
      font-size: 39px;
      line-height: 1;
      color: $brand-secondary;
    }
  }

  /**
   * Tile view
   */

  &--tile {

    .idea__author {
      font-weight: 600;

      &, & a {
        color: rgba(0, 0, 0, .5);
      }
    }
  }

  &--sm,
  &--md {
    .idea__title {
      margin-bottom: 6px;

      &, & a {
        line-height: (18 / 16);
        font-size: $font-size-base;
        color: $brand-gray;
      }
    }

    .idea__author {
      font-size: 13px;
    }

    .idea__stats {
      margin-top: 6px;
      color: $brand-gray;
    }
  }

  &--md {

    .idea__title {
      &, & a {
        color: $brand-primary;
      }
    }

    &:after {
      display: none;
    }
  }

  &.idea--dark {
    color: #fff;

    .idea__title {

      a {
        color: #fff;
        transition: color 0.2s;

        &:hover {
          text-decoration: none;
          color: rgba(#fff, 0.8);
        }
      }
    }

    .idea__stat {
      color: #fff;
    }
  }

  /**
   * Details view
   */

  &--details {

    $item-details-grid-gutter-widths: (
            xs: $grid-gutter-width-base,
            sm: $grid-gutter-width-base,
            md: 40px,
            lg: 50px,
            xl: 70px
    );

    .top-idea-badge {
      // Desktop
      position: absolute;
      right: 25px;
      top: 20px;

      // Mobile
      @include media-breakpoint-down(sm) {
        position: relative;
        top: inherit;
        right: inherit;
        display: block;
        text-align: center;
        margin-top: -10px;
        margin-bottom: 40px;
      }

      svg {
        height: 120px;
      }
    }

    .idea__idea,
    .idea__comments,
    .idea__actions,
    .idea__similar {
      padding: 40px 30px;

      @include media-breakpoint-up(md) {
        padding: 100px 110px;
      }
    }

    .idea__idea {
      padding-bottom: 20px;

      @include media-breakpoint-up(md) {
        padding-bottom: 60px;
      }
    }

    .idea__inner {
      @include make-row($grid-gutter-width-base);
    }

    .idea__main {
      @include make-col-ready($grid-gutter-width-base);
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(8);
      }
    }

    .idea__sidebar {
      @include make-col-ready($grid-gutter-width-base);
      @include make-col(12);
      margin-top: 30px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        @include make-col(4);
      }
    }


    .idea__idea .idea__title {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: (46/36);

      @include media-breakpoint-up(sm) {
        margin-bottom: 30px;
        font-size: 36px;
      }
    }

    .idea__media {
      margin-bottom: 30px;
    }

    .idea__categories {
      margin-top: 25px;
      padding: 20px 0;
      border-top: 1px solid $brand-primary;
    }

    .idea__region {
      margin-bottom: 10px;
      line-height: 27px;

      .icon-pin {
        position: relative;
        bottom: -2px;
        font-size: 18px;
        line-height: 1;
      }

      .region {
        text-decoration: underline;
      }
    }

    .idea__report {
      margin-top: 25px;
    }

    .idea__actions {
      margin-top: 20px;
      padding: 0;

      .btn {
        display: block;
        margin-bottom: 15px;

        @include media-breakpoint-up(sm) {
          display: inline-block;
          margin-right: 12px;
        }
      }
    }

    .idea__comments {
      background: #F1F1F1;

      .author__main {
        padding-top: 4px;
      }

      .author__name {
        font-size: 16px;
      }
    }

    .idea__similar {

      &__title {
        margin: 0 0 20px;
        line-height: (29/18);
        font-size: $font-size-lg;
        font-weight: 600;
      }
    }
  }

  /**
   * Idea details editing view
   */

  &--editing {
    padding: 30px 20px;

    @include media-breakpoint-up(md) {
      padding: 80px 80px 100px;
    }

    h2 {
      margin-bottom: 30px;
      font-size: 1rem;
      color: $brand-gray;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

/**
 * Idea list / grid styles
 */

.ideas {

  &--grid {

    .ideas__ideas {
      margin-top: -35px;
      align-self: flex-start;
      @include make-row();
    }

    .idea,
    .ideas__morelink {
      margin-top: 35px;
      padding-bottom: 35px;
      @include make-col-ready();
      @include make-col(12);

      &--sm,
      &--md {
        padding-bottom: 10px;
      }

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }

    .ideaRow {
      margin-top: 35px;
      padding-bottom: 0px;

      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }
    }

    .ideas__morelink {
      padding-top: 50px;
      padding-bottom: 45px;
      line-height: (30 / 24);
      font-size: 24px;
      text-decoration: none;
      font-weight: 600;
      color: $brand-gray;

      .icon-chevron-right {
        display: inline-block;
        font-size: 18px;
        transition: transform 0.3s;
      }

      &:hover {
        text-decoration: none;

        .icon-chevron-right {
          transform: translate(5px, 0);
        }
      }
    }
  }

  /**
   * 2 col idea grid for profile pages (don't want this for now
   */

  //&--grid--2 {
  //
  //    .idea {
  //        @include make-col-ready();
  //        @include make-col(12);
  //
  //        @include media-breakpoint-up(md) {
  //            @include make-col(6);
  //        }
  //    }
  //}

  &--has-sidebar {
    @include make-row();

    .ideas__ideas,
    .ideas__groups {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(9);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(10);
      }
    }

    .ideas__sidebar {
      @include make-col-ready();
      @include make-col(12);

      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        // Space above when column stacks on mobile.
        margin-top: 40px;
        text-align: center;
        border-top: 1px solid $brand-primary-dark;
        padding-top: 30px;
      }

      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(2);
      }
    }

    .ideas__actions {
      margin-top: 50px;
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(10);
        @include make-col-offset(2);
      }
    }
  }

  &--hide-dates {
    .idea__region .comma {
      display: none;
    }

    .idea__date {
      display: none;
    }
  }

  &--hide-votes {
    .idea__stat:first-child {
      display: none;
    }
  }
}

.ideas__stats {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba($brand-gray, 0.2);

  @include media-breakpoint-up(sm) {
    padding-bottom: 25px;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.ideas__stat {
  position: relative;
  padding-left: 46px;
  margin-right: 30px;

  @include media-breakpoint-up(lg) {
    margin-right: 50px;
  }

  &:last-child {
    margin-right: 0;
  }

  i {
    position: absolute;
    top: 0;
    left: 0;
    color: $brand-primary;
  }

  .icon-tick {
    font-size: 28px;
  }

  .icon-comment {
    font-size: 26px;
  }

  .label {
    font-size: $font-size-xs;
    font-weight: 500;
    color: $brand-gray;
  }

  .value {
    line-height: 1;
    font-size: 24px;
    font-weight: 500;
    color: $brand-primary;
  }
}

.ideas__groups {

}

.ideas__group {

  &__title {
    margin: 0 0 25px;
    font-size: 24px;
    line-height: (30 / 24);
    font-weight: 600;
  }

  &__lead {
    max-width: 500px;
  }

  &__group {

  }

  &__ideas {
    @include make-row();
    margin-bottom: 90px;
  }
}

/**
 * Ideas sidebar
 */

.ideas__sidebar {

  &__title,
  &__subtitle {
    line-height: (22/16);
    margin-bottom: 18px;
    font-weight: 600;
    color: $brand-primary;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  &__title {
    font-size: $font-size-lg;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: $font-size-sm;
  }

  &__body {
    margin-bottom: 40px;
    font-size: $font-size-sm;
    color: $brand-gray;
  }
}

/**
 * Ideas filter
 */

.ideas__filter {

  &__title {
    margin-bottom: 2px;
    line-height: (30/13);
    font-size: $font-size-sm;
    text-transform: uppercase;
    font-weight: 600;
    color: $brand-gray;
  }

  &__filters {
    margin-bottom: 40px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      line-height: (30/13);
      font-weight: 600;
      font-size: $font-size-sm;
    }

    a {
      display: block;
      color: $brand-gray;
    }

    .active a {
      color: $brand-primary;
    }

    .count {
      display: inline-block;
      margin-left: 7px;
      line-height: (30/10);
      font-size: 10px;
      font-weight: normal;
    }
  }
}

/**
 * Ideas search
 */

.ideas__search {
  width: 80%;
  position: relative;
  margin: 0 0 40px 10px;

  @include media-breakpoint-down(md) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 7px 20px 7px 0;
    border: none;
    border-bottom: 2px solid $brand-primary;
    font-size: 22px;
    background-color: transparent;

    &::placeholder {
      color: rgba($brand-gray, 0.6);
    }

    &:focus {
      outline: none;
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 25px;
    padding: 10px 5px;
    transform: translate(0, -50%);
    border: none;
    font-size: 11px;
    color: $brand-gray;
    background-color: transparent;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $brand-primary;
    }
  }

  .top-sort-container {
    margin-right: 50px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }


  .top-sort-container, .top-filter-container {
    display: inline-block;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }

    label {
      margin-top: 20px;
      margin-right: 20px;
      color: $brand-gray;
    }

    select {
      border: none;
      border-bottom: 2px solid $brand-primary;
      padding-bottom: 5px;
      min-width: 220px;
    }
  }


}

.ideas__month {
  position: relative;
  margin-bottom: 40px;

  .custom-select {
    width: 100%;
    padding: 8px 20px 8px 0;
    border: none;
    border-bottom: 1px solid $brand-gray;
    border-radius: 0;
    font-size: $font-size-sm;
    background-position: right 0.26rem center;

    &:invalid {
      color: rgba($brand-gray, 0.5);
    }
  }
}

.pageNumber {
  margin-left: 5px;
  margin-right: 5px;
}

#pageNumberContainer {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}

.pageNumber {
  cursor: pointer;
  margin: 0 6px;
  color: black;
  background: #F3F3F3;
}

#commentsPageNumbers .pageNumber {
  background: white;
}

.pageNumber.currentPage {
  color: #fff;
  background: $brand-primary !important;
}

.no-results-message {
  margin: 35px 15px;
  color: red;
  font-weight: bold;
}


.idea__description {
  margin-bottom: 30px;
  line-height: (29 / 18);
  white-space: pre-line; /* properly display carriage returns in idea text. */

  @include media-breakpoint-up(sm) {
    font-size: $font-size-lg;
  }
}


.idea__title .date-slot {
  font-size: 12px;
  color: $body-color;
  margin-bottom: 15px;
  font-weight: normal;
}