.quote {
    margin-top: 60px;

    &__quote {
        padding-right: 20px;
        line-height: (50/44);
        font-size: 28px;
        font-family: $font-family-serif;
        font-weight: normal;

        @include media-breakpoint-up(sm) {
            font-size: 44px;
        }
    }

    &__author {
        margin-top: 40px;
        font-weight: 600;
    }

    &__actions {
        margin-top: 40px;

        .btn {
            min-width: 160px
        }
    }
}
