
.lift-sift-table {
    border-collapse: collapse;
    border: none;


    th, td {
        vertical-align: top;
        padding: 10px 0;
    }

    th {
        padding-right: 15px;
        padding-top: 25px;
        @include media-breakpoint-up(xl) {
            padding-top: 35px;
        }
    }


    .explainer-text {
        padding: 0 0 0 20px;
        font-size: 11px;
    }

    .alert {
        font-size: 14px;
        margin: 0;
    }
}


.lift-button {
    background-color: $lift-color !important;
    &:hover, &:active, &:focus {
        background-color: $lift-color !important;
    }
}

.skip-button {
    background-color: $skip-color !important;
    &:hover, &:active, &:focus {
        background-color: $skip-color !important;
    }
}

.sift-button {
    background-color: $sift-color !important;
    &:hover, &:active, &:focus {
        background-color: $sift-color !important;
    }
}

.back-button {
    background-color: #CCCCCC !important;
    &:hover, &:active, &:focus {
        background-color: #CCCCCC !important;
    }
}

.lift-sift-progress-container {
    margin: 30px 0;

    .progress {
        border-radius: 30px;
        height: 35px;
        background-color: white;
        box-shadow: inset 0 0 7px 0 #aaaaaa;
        position: relative;

        .progress-text {
            font-weight: bold;
            position: absolute;
            top: 18px;
            text-align: center;
        }

        // Display progress bar text on either left or right side depending on which is bigger.
        .progress-text.left-label {
            left: 0;
            color: white;
        }
        .progress-text.right-label {
            right: 0;
            color: black;
        }

        .progress-bar {
            background-color: $brand-primary;
        }

    }
}

.slide-in-animation {
    animation: slidein .5s ease 0s;
}

@keyframes slidein {
    from {
        transform: translateY(20%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
