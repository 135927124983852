body {
    width: 100%;
    overflow-x: hidden;
}

@keyframes hideAnimation {
    from {opacity: 1}
    to {opacity: 0}
}

.hideAnimation {
    animation: hideAnimation 0.25s;
    animation-fill-mode: forwards;
}

@keyframes showAnimation {
    from {opacity: 0}
    to {opacity: 1}

}
.showAnimation {
    animation: showAnimation 0.25s;
    animation-fill-mode: forwards;
}

@keyframes hideAnimation {
    from {opacity: 1}
    to {opacity: 0}
}

.hideAnimation {
    animation: hideAnimation 0.25s;
    animation-fill-mode: forwards;
}

@keyframes largeToNormal {
    from {transform: scale(1.5)}
    to {transform: scale(1)}

}

.largeToNormal {
    animation: largeToNormal 0.6s;
    animation-fill-mode: forwards;
}