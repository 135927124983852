.comments {

    &__title {
        margin: 0 0 15px;
        line-height: (29/18);
        font-size: $font-size-lg;
    }

    &__login {
        padding: 25px;
        color: #737373;
        background: #fff;

        a {
            text-decoration: underline;
        }
    }

    &__comments {
        list-style: none;
        margin: 50px 0 0 0;
        padding: 0;
    }

    li:last-child .comment {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.comment {
    max-width: 600px;
    margin: 0 0 40px;
    padding: 0 0 30px;
    border-bottom: 1px solid #C0C0C0;

    &__author {
        margin-bottom: 15px;
    }

    abbr {
        text-decoration: none;
    }
}
