.mobile-filters {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.mobile-filter {
    float: left;
    width: 50%;
    margin-bottom: 40px;
    font-size: 12px;
    font-weight: 600;

    .label {
        text-transform: uppercase;
    }

    .value {

    }
}

.mobile-filters-menu {

    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 70px;
        color: #fff;
        overflow: auto;
        background: $brand-primary;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        transition: all 0.35s;

        a {
            color: #91ABFF;
        }

        .active a {
            color: #fff;
        }

        .ideas__filter__title {
            color: $body-color;
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    .close {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 10px;
        line-height: 1.1;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        text-shadow: none;
        opacity: 1;
        @extend .icon-font;

        &:before {
            content: unicode($icon-cross-thin);
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
