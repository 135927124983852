/**
 * Mixins
 */

@mixin default-button-scheme($color, $background, $border, $hover-background: $background, $hover-border: $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus,
  &:hover {
    color: $color;
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:active,
  &.active {
    color: $color;
    background-color: $hover-background;
    border-color: $hover-border;

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: $hover-background;
      border-color: $hover-border;
    }
  }
}

@mixin outline-button-scheme($color, $alt-color: #fff) {
  color: $color;
  background-color: transparent;
  border-color: $color;
  transition: all 0.3s linear;

  &:focus,
  &.focus,
  &:hover {
    color: $alt-color;
    background-color: $color;
    border-color: $color;
  }

  &:active,
  &.active {
    color: $alt-color;
    background-color: $color;
    border-color: $color;

    &:hover,
    &:focus,
    &.focus {
      color: $alt-color;
      background-color: $color;
      border-color: $color;
    }
  }
}

/**
 * Default button styles overrides
 */

.btn {
  @include button-size(4px, 26px, 14px, (37px/2), 18.5px);
  @include box-shadow(none);
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: 600;
  border-width: 2px;
  cursor: pointer;

  @include media-breakpoint-up(sm) {
    border-width: 3px;
  }

  @include media-breakpoint-up(md) {
    @include button-size(12px, 35px, 18px, (54px/2), 27px);
  }

  &.disabled {
    opacity: 0.25;
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: none;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: none;
    @include box-shadow(none);
  }
}

/**
 * Additional colors
 */

.btn-primary {
  @include default-button-scheme(#fff, $brand-primary, $brand-primary, $brand-primary-dark, $brand-primary-dark);
}

.btn-secondary {
  @include default-button-scheme(#fff, $brand-secondary, $brand-secondary);
}

.btn-white {
  @include default-button-scheme($brand-primary, #fff, #fff, #F3F3F3, #F3F3F3);
}

.btn-light-gray {
  @include default-button-scheme(#737373, #F3F3F3, #F3F3F3, darken(#F3F3F3, 3%), darken(#F3F3F3, 3%));
}

.btn-gray {
  @include default-button-scheme(#fff, $brand-gray-light, $brand-gray-light, darken($brand-gray-light, 3%), darken($brand-gray-light, 3%));
}

.btn-dark {
  @include default-button-scheme(#fff, rgba(#1D1D1D, 0.6), rgba(#1D1D1D, 0.6));
}

/**
 * Buttons with a shadow
 */

.btn-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/**
 * Outline buttons
 */

.btn-outline {

  &.btn-primary {
    @include outline-button-scheme($brand-primary, #fff);
  }

  &.btn-secondary {
    @include outline-button-scheme($brand-secondary, #fff);
  }

  &.btn-white {
    @include outline-button-scheme(#fff, $brand-primary);
  }

  &.btn-gray {
    @include outline-button-scheme($brand-gray, #fff);
  }

  &.btn-dark {
    @include outline-button-scheme(rgba(#1D1D1D, 0.6), rgba(#1D1D1D, 0.6));

    &:hover {
      @include default-button-scheme($brand-primary, #fff, #fff);
    }
  }
}


.circle-button {
  border: none;
  display: block;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  text-align: center;
  background: $brand-gray;
  color: #fff;
  transition: all 0.3s;

  @include media-breakpoint-up(xl) {
    width: 72px;
    height: 72px;
    line-height: 72px;
  }

  i {
    display: inline-block;
    vertical-align: middle;
    transition: all 0.3s;
    font-size: 20px;

    @include media-breakpoint-up(xl) {
      font-size: 25px;
    }
  }

  &:hover, &:active, &:focus {
    color: #fff;
    text-decoration: none;

    i {
      transform: scale(1.1);
    }
  }
}


/**
 * Button sizes
 */

.btn-sm {
  @include button-size(5px, 26px, 14px, (37px/2), 18.5px);
  line-height: (23/14);
  border-width: 2px;
  font-weight: 600;

  @include media-breakpoint-up(sm) {
    border-width: 2px;
  }
}
