$share-width-default: 140px;
$share-width-active: 150px;

.share {
    position: relative;
    cursor: pointer;
    transition: all 0.3s;

    @include media-breakpoint-up(sm) {
        width: $share-width-default;
    }

    ul {
        margin: 0;
        padding: 0 15px;
        list-style: none;
    }

    li {
        float: left;
        width: 33%;
        line-height: 30px;
        text-align: center;
    }

    a {
        display: block;
        line-height: 30px;

        &:hover {
            text-decoration: none;
        }
    }

    i {
        font-size: 16px;
        line-height: 30px;
    }

    .icon-google-plus {
        position: relative;
        font-size: 22px;
    }

    &__default,
    &__active {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
    }

    &__default {
        line-height: 32px;
    }

    &__active {
        opacity: 0;
        visibility: hidden;
    }

    &:hover {

        @include media-breakpoint-up(sm) {
            width: $share-width-active;
        }

        .share__default {
            opacity: 0;
            visibility: hidden;
        }

        .share__active {
            opacity: 1;
            visibility: visible;
        }
    }
}

.share-buttons {
    @extend .clearfix;
    margin-top: -10px;
    margin-bottom: 20px;

    .share-button {
        float: left;
        margin-top: 10px;
        margin-right: 10px;
    }

    a {
        display: block;
        width: 85px;
        height: 42px;
        line-height: 42px;
        border-radius: 21px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        background: #2757EF;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        transition: all 0.2s;

        &:after {
            display: none;
        }

        &:hover {
            transform: translate(-2px, -2px);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
        }
    }

    i {
        position: relative;
        bottom: -1px;
    }

    .icon-google-plus {
        font-size: 130%;
        bottom: -3px;
    }
}
