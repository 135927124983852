.thread {
    position: relative;

    &--list {
        display: block;
        border-top: 1px solid rgba(#979797, 0.2);
        padding: 20px;
        transition: all 0.5s;

        @include media-breakpoint-up(sm) {
            padding: 30px 40px;
        }

        &:hover {
            text-decoration: none;
            background: rgba($brand-primary, 0.06);
        }

        .thread__participants {

        }

        .thread__participant {
            display: inline-block;
            font-weight: 600;
            line-height: 1;
            font-size: $font-size-base;
            color: $brand-primary;

            &:after {
                content: ',';
                color: $brand-gray-dark;
            }

            &:last-child:after {
                content: '';
            }
        }

        .thread__title {
            margin: 5px 0 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: (16/13);
            font-weight: normal;
            font-size: 13px;
            color: $brand-gray-dark;
        }

        .thread__timestamp {
            position: absolute;
            right: 40px;
            top: 30px;
            line-height: (22/13);
            font-size: 13px;
            color: $brand-gray;
        }
    }
}

.threads {

    &--list {

    }
}
