
/**
 * Idea voting widget
 */

.vote--idea-next {
    width: 120px;
}

.vote--idea {
    @extend .clearfix;
    text-align: center;
    overflow: hidden;
    padding-bottom: 20px;

    .vote__label {
        margin-top: 22px;
        margin-bottom: 22px;
    }

    .vote__option {
        display: block;
        float: left;
        width: 50%;
        max-width: 70px;

        @include media-breakpoint-up(lg) {
            max-width: none;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .vote__value {
        margin-top: 3px;
        font-size: 24px;
        opacity: 0.4;
        color: $brand-gray;

        @include media-breakpoint-up(xl) {
            font-size: 36px;
        }
    }
}

// Change bullet point color if viewed.
.is-viewed-but-not-voted {
    background-color: $skip-color !important;
}


// Colour button if voted up.
.is-voted-up {
    background-color: $lift-color !important;
}



/**
 * Comment voting widget
 */

.vote--comment {

    .vote__value {
        margin-left: 5px;
        font-weight: 600;
        color: $brand-gray;
        font-size: 13px;
    }

    .vote__up,
    .vote__down {
        display: inline-block;
        padding: 6px;
        line-height: 1;
        text-align: center;
        color: $brand-gray;

        i {
            font-size: 12px;
        }

        &:hover, &:focus, &:active {
            text-decoration: none;
        }
    }

    &.vote--up {
        .vote__up,
        .vote__up .vote__value {
            color: $brand-secondary;
        }
    }

    &.vote--down {
        .vote__down,
        .vote__down .vote__value {
            color: $brand-secondary;
        }
    }
}


