

/**
 * Global form styles
 */

.form {
    select {
        width: 100%;
    }

    .custom-file {
        width: 100%;
    }
}

.form-group {
    margin-bottom: 25px;
}

.form-text {

}

.form-footer {
    margin-top: 40px;

    .btn {
        margin-right: 7px;
    }

    .btn-block {
        padding-top: 14px;
        padding-bottom: 13px;
        border-radius: 0;
        font-size: 14px;
    }
}

.control-label {
    color: $brand-gray;
    font-weight: 600;
}

.form-control {
    font-family: $font-family-sans-serif;

    &::placeholder {
        transition: color 0.2s;
    }

    &:focus::placeholder {
        color: transparent !important;
    }
}

.custom-control-description {
    font-size: 14px;
}

.custom-checkbox {

    .custom-control-description {
        margin-top: 3px;
    }

    .custom-control-indicator {
        border-radius: 0;
        box-shadow: none;
        background: none;
        font-size: 18px;

        &:before {
            @extend .icon-font;
            content: unicode($icon-checkbox);
            color: rgba($brand-gray, 0.5);
            vertical-align: top;
            transition: color 0.2s;
        }
    }

    .custom-control-input:checked ~ .custom-control-indicator {
        background: none;
        box-shadow: none;

        &:before {
            content: unicode($icon-checkbox-checked);
            color: $brand-primary;
        }
    }

    .custom-control-input:focus ~ .custom-control-indicator {
        box-shadow: none;
    }
}

.custom-file-control {
    &::before {
        height: 37px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

.custom-file-button {
    background-color: transparent;
    border: none;

    &:lang(en)::before {
        content: 'Choose file';
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-sm;
        padding-left: 40px;
        padding-right: 40px;
        left: -1px;
        right: auto;
        border: none;
        line-height: 32px !important;
    }

    &::after {
        content: attr(data-content) !important;
        position: absolute;
        top: 0px;
        left: 180px;
        display: block;
        height: 100%;
        overflow: hidden;
        padding: 0.5rem 1rem;
    }
}

.form-success {
    display: none;

    &__icon {
        margin-bottom: 15px;
        line-height: 1;
        font-size: 38px;
    }

    &__title {
        line-height: (25/18);
        font-size: $font-size-lg;
        font-weight: 600;
    }
}

.error-message {
    margin: 0 0 20px;

    &, p {
        line-height: (19/13);
        font-size: $font-size-sm;
        font-weight: 500;
        color: #F75B36 !important;
    }

    p {
        margin: 0 0 6px 0 !important;
    }
}

.success-message {
    &, p {
        color: #1DB3BC !important;
    }
}

/**
 * Override select2 styles
 */

.select2-container {
    width: 100% !important;
    padding-bottom: 3px;
}

.select2-container-multi .select2-choices {
    @extend .form-control;
    padding: 4px 0;
    box-shadow: none !important;

    .is-focus & {
        border-bottom-color: $brand-primary;
    }
}

.select2-container-multi .select2-choices .select2-search-choice {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 3px;
    padding: 5px 20px 5px 5px;
    border: none;
    border-radius: 0;
    background-color: #F3F3F3;

    &:first-child {
        margin-left: 0;
    }

    .profile-icon {
        width: 16px;
        height: auto;
    }
}

.select2-search input.select2-active,
.select2-container-multi .select2-choices .select2-search-field input.select2-active {
    background-color: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input {
    padding-left: 0;
}

.select2-drop {
    padding: 6px;
    border: none;
    background: #fff;
    box-shadow: 5px 10px 21px rgba(#000, 0.12);
}

.select2-results .select2-no-results {
    background-color: transparent;
}

.select2-results .select2-highlighted {
    background-color: #F3F3F3;
}

.select2-container-multi .select2-search-choice-close {
    left: auto;
    right: 5px;
    top: 7px;
}

/**
 * Form with labels on the left of the input
 */

.form-grid {

    .form-group {
        @include make-row();
    }

    .control-label {
        @include make-col-ready();
        @include make-col(12);
        line-height: 36px;

        @include media-breakpoint-up(lg) {
            @include make-col(3);
        }
    }

    .control-wrapper {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(lg) {
            @include make-col(7);
        }
    }

    .form-check {
        clear: both;
        float: none;
        width: 100%;
    }

    .form-group > .form-check {
        margin-left: 15px;
    }

    .form-text + .form-check {
        margin-top: 10px;
    }
}

/**
 * Custom form groups
 */

.form-group--profile-photo {
    margin-bottom: 50px;

    .form-media {
        float: left;
        margin-right: 25px;
    }

    .form-buttons {
        float: left;
        margin-top: 40px;
    }
}

.form-group--dob {

    .date-of-birth {
        @extend .clearfix;
    }

    .date-of-birth-month-wrap,
    .date-of-birth-day-wrap,
    .date-of-birth-year-wrap {
        float: left;
        width: 31.3%;
    }

    .date-of-birth-month-wrap,
    .date-of-birth-day-wrap {
        margin-right: 3%;
    }
}

.form-group--rating {

    .rating {
        margin-top: 7px;
        margin-bottom: 15px;
    }
}

.form-group + .form-group--checkboxes {
    margin-top: 50px;
}

/**
 * Form with underlined inputs
 */

.form-underline {

    .form-control,
    .custom-select {
        padding-left: 0;
        padding-right: 0;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;
        background-color: transparent;

        &:focus {
            border-color: $brand-primary;
        }
    }
}

/**
 * Form with help text on the right
 */

.form-help-right {

    .form-group {
        @include make-row();
    }

    .control-wrapper {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }

        .form-text {
            @include make-col(12);
            margin-top: 15px;
            padding: 0;
        }
    }

    .form-text {
        @include make-col-ready();
        @include make-col(12);
        margin-top: 15px;

        @include media-breakpoint-up(lg) {
            @include make-col(4);
            margin-top: 28px;
        }
    }

    .form-group--textarea-scale {
        .form-text {
            @include media-breakpoint-up(lg) {
                margin-top: 66px;
            }
        }
    }
}

/**
 * Single input form with the arrow button above the input
 * (Eg. newsletter)
 */

.form-group--single-input {

    .control-wrapper {
        position: relative;
    }

    [type="submit"] {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;
        width: 40px;
        height: 60px;
        border: none;
        font-size: 24px;
        opacity: 0;
        transition: all 0.3s;

        &:before,
        &:after {
            @extend .icon-font;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s;
        }

        &:before {
            content: unicode($icon-arrow-right-circle);
        }

        &:after {
            content: unicode($icon-arrow-right-sign);
            opacity: 0;
        }

        &:hover {
            &:before {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }
    }

    &.is-full {
        [type="submit"] {
            opacity: 1;
        }
    }
}

/**
 * Floating labels
 */

.form-floating-labels {

    .control-label:not(.control-label-lg) {
        transform: translate(0, 20px);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
    }

    .is-focus,
    .is-full {
        .control-label {
            transform: translate(0, 0);
            opacity: 1;
            visibility: visible;
        }
    }
}

/**
 * Light color scheme (white / gray background)
 */

.form-light {
    color: $brand-gray;

    .is-empty {
        .custom-select {
            color: $input-color-placeholder;
        }
    }
}

/**
 * Dark form color scheme (on a blue background)
 */

.form-dark {
    color: #fff;

    .control-label {
        color: $translucent-white;
    }

    .control-label-lg {
        max-width: 260px;
        color: #1D1D1D;
    }

    .form-text {
        color: $translucent-white;
        transition: all 0.3s;
    }

    .form-control,
    .custom-select {
        background-color: transparent;
        border-bottom-color: $translucent-white;
        color: #fff;

        &:focus {
            border-width: 3px;
            border-color: #fff;
        }
    }

    .form-control {
        &::placeholder {
             color: $translucent-white;
        }
    }

    .custom-select {
        // Remove black arrows on blue background on dropdown.
        //background-image: $custom-select-indicator-dark;
    }

    .custom-file-button {
        &::before {
            @extend .btn-dark;
        }
        &::after {
            color: #fff;
        }
    }

    .form-group--single-input .btn {
        color: #fff;
    }

    .form-group--textarea-scale {



        .form-control {
            background: rgba(#fff, 0.07);
            color: #fff;
        }

        &.is-focus,
        &.is-full {
            .control-label {
                color: #fff;
            }
        }
    }

    .is-focus {
        .form-text {
            color: #fff;
        }
    }

    //.is-empty {
    //    .custom-select {
    //        color: $translucent-white;
    //    }
    //}
}

/**
 * Slightly differnt light theme
 * (used for the edit idea form)
 */

.form-light-alt {

    .form-text {
        color: $brand-gray;
        transition: all 0.3s;
    }

    .form-control,
    .custom-select {
        background-color: transparent;
        border-bottom-color: $body-color;
        color: $brand-primary;

        &:focus {
            border-width: 3px;
            border-color: $brand-primary;
        }
    }

    .is-full .form-control,
    .is-full .custom-select {
        border-width: 3px;
        border-color: $brand-primary;
    }

    .form-control {
        &::placeholder {
            color: $brand-gray;
        }
    }

    .custom-select {
        background-image: $custom-select-indicator-dark;
    }

    .custom-file-button {
        &::before {
            @extend .btn-dark;
        }
        &::after {
            color: $body-color;
        }
    }

    .form-group--single-input .btn {
        color: #fff;
    }

    .form-group--textarea-scale {

        .control-label {
            color: $brand-gray;
        }

        .form-control {
            background: #f2f2f2;
            color: $body-color;
        }

        &.is-focus,
        &.is-full {
            .control-label {
                color: $brand-gray;
            }
        }
    }

    .is-focus {
        .form-text {
            color: $brand-gray;
        }
    }

    .is-empty {
        .custom-select {
            color: $brand-gray;
        }
    }
}

/**
 * Large form (submit page)
 */

.form-lg {

    .form-group {
        margin-bottom: 20px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 40px;
        }
    }

    .control-label {
        margin-bottom: 5px;
        line-height: (16/13);
        font-size: 13px;
        text-transform: uppercase;
    }

    .control-label-lg {
        margin-bottom: 15px;
        line-height: (22/18);
        font-size: $font-size-lg;
        text-transform: none;
        font-weight: 500;
    }

    .form-control,
    .custom-select {
        font-size: 24px;
        font-weight: 600;
        font-family: $font-family-serif;

        @include media-breakpoint-up(sm) {
            font-size: 46px;
        }
    }

    .custom-select {
        height: 47px;
        background-size: 16px 16px;
        transition: color 0.2s;

        @include media-breakpoint-up(sm) {
            height: 74px;
            background-size: 24px 24px;
        }
    }

    //.is-focus .custom-select {
    //    font-size: 100%;
    //    color: transparent;
    //}

    .is-full .custom-select {
        color: #fff !important;
    }

    .custom-select:focus {
      color: $brand-gray;
    }

    .custom-select:not(:focus) {
      color: $translucent-white;
    }

    .form-text {
        font-size: 13px;
    }

    /**
     * Text area that is scaled back to to the usual size
     *
     * Floating labels are also removed and the label recieved
     * the focused / filled stlying.
     */
    .form-group--textarea-scale {

        .control-label {
            margin-bottom: 14px;
            font-size: 24px;
            font-weight: 600;
            font-family: $font-family-serif;

            transform: none;
            opacity: 1;
            visibility: visible;
            text-transform: none;

            @include media-breakpoint-up(sm) {
                font-size: 46px;
            }
        }

        .form-control {
            padding: 25px;
            line-height: (29/18);
            font-size: $font-size-lg;
            font-family: $font-family-sans-serif;
            font-weight: normal;
            border: none;

            &::placeholder {
                color: transparent;
            }
        }
    }
}

/**
 * Specific form overrides
 */

.form--newsletter {
    @extend .form;
    @extend .form-floating-labels;
    @extend .form-underline;
    @extend .form-dark;

    .form-control {
        padding: 16px 40px;
        line-height: (25/18);
        font-size: $font-size-lg;
        text-align: center;
        font-weight: 600;
        border-bottom: 2px solid rgba(#fff, 0.6);

        &::placeholder {
            color: rgba(#fff, 0.4);
        }

        &:focus {
            border-bottom: 2px solid #fff;
        }
    }

    .control-label {
        position: relative;
        bottom: -5px;
        margin-bottom: 0;
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;
    }
}

.form--comment {
    @extend .form;

    .form-control {
        border: none;
        border-radius: 0;

        &::placeholder {
            color: $brand-gray;
        }
    }

    textarea {
        padding: 25px;
    }
}

.form--compose {
    @extend .form;
    @extend .form-underline;
    @extend .form-grid;

    .control-wrapper {
        @include media-breakpoint-up(lg) {
            @include make-col(9);
        }
    }

    textarea {
        min-height: 120px;
    }
}

.form--message-reply  {

    textarea {
        height: 77px;
        line-height: 37px;
    }
}

.form--submit-idea {
    @extend .form;
    @extend .form-floating-labels;
    @extend .form-help-right;
    @extend .form-underline;
    @extend .form-lg;
    @extend .form-dark;

    textarea {
        min-height: 180px;

        @include media-breakpoint-up(lg) {
            min-height: 320px;
        }
    }

    hr {
        margin-top: 50px;
        margin-left: 0;
        margin-right: 0;
        border-top-color: rgba($brand-gray-dark, 0.4);

        @include media-breakpoint-up(lg) {
            width: percentage(8/12);
        }
    }

    .form-footer {
        margin-top: 30px;
    }

    .custom-select {
        padding-right: 45px;
    }
}

.form--edit-idea {
    @extend .form;
    @extend .form-floating-labels;
    @extend .form-help-right;
    @extend .form-underline;
    @extend .form-lg;
    @extend .form-light-alt;

    textarea {
        min-height: 180px;
        background: #F7F7F7;

        @include media-breakpoint-up(lg) {
            min-height: 320px;
        }
    }

    hr {
        margin-top: 50px;
        margin-left: 0;
        margin-right: 0;
        border-top-color: rgba($brand-gray-dark, 0.4);

        @include media-breakpoint-up(lg) {
            width: percentage(8/12);
        }
    }

    .form-footer {
        margin-top: 30px;
    }

    .btn-outline.btn-gray {
        border-color: #BCBCBC;
        color: #BCBCBC;

        @include media-breakpoint-up(lg) {
            min-width: 216px;
        }
    }
}

.form--auth {
    @extend .form;
    @extend .form-floating-labels;
    @extend .form-underline;
    @extend .form-light;

    .form-group {
        margin-bottom: 10px;
    }

    .control-label {
        margin-bottom: 2px;
        text-transform: uppercase;
        font-size: 10px;
    }

    .form-control {
        margin-bottom: 10px;
        color: $brand-gray;
    }

    .form-text {
        margin: -3px 0 0 0;
        font-size: 10px;
    }

    .form-recaptcha {
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 20px;
    }
}

.form--contact {
    @extend .form;
    //@extend .form-floating-labels;
    @extend .form-underline;
    //@extend .form-lg;
    @extend .form-light;

    textarea#contact-message-textarea {
        min-height: 180px;
        border: 1px solid #cccccc;
        padding: 10px;

        @include media-breakpoint-up(lg) {
            min-height: 320px;
        }
    }
}

.form--overlay-search {
    @extend .form;
    @extend .form-underline;
    @extend .form-lg;
    @extend .form-dark;

    .form-control {
        padding: 0;
        line-height: 1;
        font-size: 38px;
        font-weight: 500;
        border: none;
        color: #000000;

        @include media-breakpoint-up(sm) {
            font-size: 96px;
        }
    }
}
