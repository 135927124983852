.hero {
  position: relative;
  padding-top: 120px;
  padding-bottom: 40px;
  background-color: $brand-primary;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  color: #fff;

  a {
    color: #fff;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &__title {
    position: relative;
    line-height: (68/56);
    font-size: 38px;

    @include media-breakpoint-up(sm) {
      font-size: 56px;
    }

    p {
      margin: 0;
    }
  }

  &__editable {
    h1, h2, h3 {
      line-height: (68/56);
      font-size: 38px;

      @include media-breakpoint-up(sm) {
        font-size: 56px;
      }
    }
  }

  &__actions {
    position: relative;

    .btn {
      margin: 5px;

      @include media-breakpoint-up(sm) {
        margin: 10px;
        min-width: 166px;
      }
    }
  }

  &__nav {

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      float: left;
      padding: 0 9px;
      text-transform: uppercase;
      line-height: (27/13);
      font-weight: 600;
      font-size: 13px;
    }

    a {
      display: block;
      color: rgba(255, 255, 255, 0.4);
      transition: all 0.3s;

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }

    .current a {
      color: #fff;
    }
  }

  &--cta {

    padding: 0;

    .hero__title.hero-hook {
      margin: 10px;
      font-size: 24px;
      @include media-breakpoint-up(sm) {
        font-size: 34px;
      }
    }

    .hero__title.hero-title {
      margin: 10px;
      font-size: 51px;
      @include media-breakpoint-up(sm) {
        font-size: 61px;
      }
    }

    .hero__title.hero-description {
      max-width: 450px;
      margin: auto;
      font-size: 16px;
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
    }

    .hero__title.hero-preview {
      margin: 50px 0;
      img {
        max-width: 350px;
        @include media-breakpoint-up(sm) {
          max-width: 600px;
        }
      }
    }

    .hero__title.hero-preview.home {
      margin: 120px 0 0;
    }

    .app-action {
      margin: auto 10px;
      max-height: 47px;
      @include media-breakpoint-up(sm) {
        max-height: 57px;
      }
    }

    .flex-container {
      margin: 0 70px;
      padding: 120px 0 0;
      gap: 80px;
      align-items: center;
      display: inline-flex;
      flex-direction: row;

      @include media-breakpoint-down(sm) {
        margin: 10px 20px;
        flex-direction: column;
      }
    }

    .flex-container.home {
      padding: 0;
      margin-bottom: 0;
    }

    .flex-child {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1em;
      align-items: center;
    }
  }

  &--home {

    // TOP IDEA on Homepage
    .top-idea-card {
      text-align: left;
      color: $body-color;

      padding: 50px;

      @include media-breakpoint-down(sm) {
          padding: 20px;
        }

      .card__header {
        color: $brand-primary;
        align-items: center;
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }

        .top-idea-badge {
          float: left;
          margin-right: 20px;

          @include media-breakpoint-down(sm) {
            float: none;
            display: block;
            margin: 0 auto;
          }
        }

        h2 {
          margin-top: 30px;
          margin-left: 20px;
          color: $brand-primary;

          @include media-breakpoint-down(sm) {
            margin-top: 0;
            text-align: center;
            font-size: 25px;
          }

        }
      }

      .card__body {
        padding: 20px;
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(lg) {
          @include make-col(8);
          @include make-col-offset(2);
        }
        //padding: 0 50px 50px 50px;

        a {
          color: $brand-primary;
        }

        .btn {
          display: block;
          margin: 0 auto;
          width: 90%;
          margin-bottom: 40px;
        }

        .btn:hover {
          color: #ffffff;
        }
      }
    }


    @include media-breakpoint-up(sm) {
      padding-top: (10px + $header-outer-height);
      padding-bottom: 70px;
    }

    .hero__title {
      font-size: 35px;
      line-height: (88/86);

      .icon-dot {
        margin-left: 2px;
        font-size: 9px;
      }

      @include media-breakpoint-up(sm) {
        font-size: 68px;

        .icon-dot {
          position: relative;
          top: -1px;
          font-size: 15px;
        }
      }

      @include media-breakpoint-up(lg) {
        font-size: 86px;

        .icon-dot {
          font-size: 20px;
        }
      }
    }

    .hero__title.homepage-hero {
      // Smaller text for homepage hero title on larger screens.
      @include media-breakpoint-up(sm) {
        font-size: 60px;
      }
    }

    .flex-container {
      margin: 10px 70px;
      gap: 30px;
      align-items: center;
      display: flex;
      flex-direction: row;

      @include media-breakpoint-down(sm) {
        margin: 10px 20px;
        flex-direction: column;
      }
    }

    .flex-child {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4em;
      align-items: center;
    }  

    //.flex-child:first-child {
    //  margin-left: 70px;
    //}
    //
    //.flex-child:last-child {
    //  margin-right: 70px;
    //}
    //
    //.flex-child+.flex-child{
    //  margin-left: 50px;
    //}
  }

  &--ideas {
    text-align: left;

    @include media-breakpoint-up(sm) {
      padding-top: (30px + $header-outer-height);
      padding-bottom: 70px;

      @include media-breakpoint-up(xl) {
        padding-left: 75px;
      }
    }

    .hero__inner {
      @include make-row();
    }

    .hero__content {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(xl) {
        @include make-col(10);
        @include make-col-offset(2);
      }
    }

    .hero__title {
      margin-bottom: 0;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
    }

    .hero__lead {
      margin: 15px 0 0;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    li {
      float: left;
      margin-right: 25px;
      margin-bottom: 10px;
      font-size: $font-size-lg;
      font-weight: 600;
      line-height: (30/24);

      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }

      &.active a {
        color: #fff;
      }
    }

    a {
      display: inline-block;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  &--idea {
    height: 355px;
    padding: 0;

    @include media-breakpoint-up(sm) {
      height: $header-outer-height + 340px;
    }
  }

  &--profile {
    height: 355px;
    padding: 0;

    @include media-breakpoint-up(sm) {
      height: $header-outer-height + 340px;
    }
  }

  &--account {
    padding-bottom: 88px;
    text-align: left;

    @include media-breakpoint-up(sm) {
      height: 375px;
      padding: 0 0 88px;
    }

    .container {
      height: 100%;
    }

    .hero__inner {
      margin: 0;
      padding: 50px 0 0 0;

      @include media-breakpoint-up(sm) {
        padding: 180px 0 0 0;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 240px;
      }
    }

    .hero__title {
      margin: 0;
    }

    .hero__nav {
      margin-left: -10px;

      @include media-breakpoint-up(lg) {
        position: absolute;
        right: 15px;
        bottom: 6px;
      }
    }

    .profile-link {
      display: block;
      margin: 10px 0;
      font-size: 0.8125rem;
      font-weight: 500;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        display: inline-block;
        margin: 0 0 0 10px;
      }
    }
  }

  &--submit {
    padding-bottom: 0;
    text-align: left;

    @include media-breakpoint-up(sm) {
      padding-top: 230px;
    }

    .hero__inner {
    }

    .hero__content {
      @include make-row();
    }

    .hero__title {
      @include make-col-ready();
      margin-bottom: 30px;

      @include media-breakpoint-up(lg) {
        @include make-col(7);
        @include make-col-offset(2);
      }
    }

    .hero__lead,
    .hero__headline {
      @include make-col-ready();
      font-size: $font-size-lg;
      line-height: (29/18);
      font-weight: 600;

      @include media-breakpoint-up(lg) {
        @include make-col(3);
        @include make-col-offset(2);
      }
    }

    .hero__headline {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }

    .hero__body {
      @include make-col-ready();
      font-size: $font-size-lg;
      line-height: (29/18);

      strong {
        margin-bottom: 10px;
        display: block;
        font-weight: 600;
      }

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
  }

  &--static-page {
    background-image: url('./img/background-4.svg');
    text-align: left;

    @include media-breakpoint-up(sm) {
      padding-top: 110px;
      padding-bottom: 70px;
    }

    .hero__editable {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(9);
        @include make-col-offset(1);
      }
    }
  }

  &--legals {
    text-align: left;

    @include media-breakpoint-up(sm) {
      padding-top: 230px;
      padding-bottom: 90px;
    }

    .hero__inner {
      @include make-row();
    }

    .hero__editable {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(6);
        @include make-col-offset(1);
      }

      h1, h2, h3 {
        @include media-breakpoint-up(sm) {
          font-size: 46px;
        }
      }

      p {
        max-width: 530px;
        margin: 10px 0 0;
        line-height: (29/18);
        font-size: $font-size-lg;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
