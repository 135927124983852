.nav {

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__mobile {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__desktop {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__notification,
    &__messages {
        position: relative;
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        i {
            position: relative;
            bottom: -2px;
            font-size: 17px;
        }
    }

    &__messages {
        margin-left: 20px;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: -11px;
            width: 2px;
            height: 14px;
            transform: translate(0, -60%);
            background: #fff;
        }
    }

    &__user {
        padding: 0;

        @include media-breakpoint-up(md) {
            margin-left: 16px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .profile-photo {
        margin-top: -2px;
        border-radius: 50%;
    }

    .username {
        display: inline-block;
        margin-left: 6px;
    }

    &--primary {

        > ul {
            padding: 0;

            @include media-breakpoint-up(md) {
                margin-top: 5px;
            }
        }

        li {
            margin-bottom: 16px;

            @include media-breakpoint-up(md) {
                float: left;
                margin-bottom: 0;
            }
        }

        a {
            display: inline-block;
            padding: 6px 0;
            color: #fff;
            line-height: (21/18);
            font-size: $font-size-lg;
            transition: opacity 0.3s;
            padding-bottom: 6px;

            @include media-breakpoint-up(md) {
                display: block;
                padding: 0 9px;
                text-transform: uppercase;
                line-height: 32px;
                font-size: $font-size-sm;
            }

            &:hover {
                opacity: 0.8;
            }

            &:hover, &:focus, &:active {
                text-decoration: none;
            }
        }

        .icon-search {
            display: none;
            position: relative;
            bottom: -1px;
            font-size: 13px;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }

        .current {
            a {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background: #fff;

                    @include media-breakpoint-up(md) {
                        left: 5px;
                        right: 5px;
                        height: 3px;
                    }
                }
            }
        }

        .no-current {
            a:after {
                display: none;
            }
        }

        .nav__user {
            padding: 0;
        }
    }

    &--secondary {

        ul {
            padding: 0;
            margin: 0;
        }

        li {
            margin-bottom: 20px;
        }

        a {
            padding: 0 9px;
            line-height: (21/18);
            font-size: $font-size-lg;
            color: rgba(#fff, 0.5);
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &--footer {
        font-size: 14px;

        ul {
            @extend .clearfix;
            width: 100%;
        }

        li {
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 15px;

            @include media-breakpoint-up(md) {
                float: left;
                display: block;
                margin-left: 0;
                margin-right: 20px;
            }

            @include media-breakpoint-up(lg) {
                margin-right: 0;
                min-width: 120px;
            }

            &:nth-child(2n + 1) {
                @include media-breakpoint-up(lg) {
                    clear: left;
                }
            }
        }

        .copyright {
            width: 100%;
            margin-top: 10px;
            clear: left;
            white-space: nowrap;

            @include media-breakpoint-up(lg) {
                width: auto;
                margin-top: 0;
                clear: none;
            }
        }

        a {
            display: inline-block;
            color: #000;
            text-decoration: none;
            border-bottom: 1px solid #000;
        }
    }

    &--socials {
        margin-left: -12px;
        margin-right: -12px;
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
        }

        @include media-breakpoint-up(xl) {
            justify-content: flex-end;
        }

        a {
            display: block;
            padding: 12px;
            line-height: 1;
            font-size: 16px;
            color: $brand-gray;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
