.idea-modal {

    .modal-content {
        padding: 12px;
        border-radius: 40px;

        @include media-breakpoint-down(sm) {
            border-radius: 20px;
        }

        .modal-body {
            padding: 20px;
        }
    }

    .modal-sidebar-table {
        margin: 0 auto;
        font-size: 0.9em;
        border-collapse: collapse;

        th {
            padding: 4px 10px 4px 0;
        }
    }

    // Margin on top when bootstrap columns stack.
    @media(max-width: 575px) {
        .modal-sidebar-table {
            margin-top: 40px;
        }
    }

    .idea-title {
        color: $brand-primary;
        margin: 0;
        font-size: 22px;
    }

    .idea-body-text {
        font-size: 14px;
        white-space: pre-line;
    }

    .author--footer {
        margin: 30px 0 5px 0;
    }

}
