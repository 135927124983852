.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow: hidden;
    overflow-y: auto;
    // This is to trick the browser into thinking the input is visible
    // Otherwise we can't set focus
    opacity: 0.00001;
    pointer-events: none;
    transition: all 0.35s;

    a.overlay__close {
        position: absolute;
        top: 18px;
        right: 15px;
        padding: 10px;
        font-size: 23px;
        line-height: 1;
        color: rgba(#000, 0.2);
        z-index: 100;
        cursor: pointer;
        transition: all 0.2s;

        @include media-breakpoint-up(sm) {
            top: 40px;
            right: 40px;
            padding: 20px;
        }

        &:hover {
            text-decoration: none;
            color: #fff;
        }
    }

    &--primary {
        background: $brand-primary;
        color: #fff;
    }

    &--open {
        opacity: 1;
        pointer-events: all;
    }
}

body.overlay-open {
    overflow: hidden;
}

/**
 * Search overlay
 */

.overlay--search {
    padding: 30px 0;

    @include media-breakpoint-up(sm) {
        padding: 60px 0;
    }

    .overlay__header {
        margin-bottom: 40px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 80px;
        }
    }

    .overlay__title {
        margin: 0 0 30px;
        line-height: (23/14);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        color: rgba(#000, 0.5);

        @include media-breakpoint-up(sm) {
            margin-bottom: 40px;
        }

        i {
            position: relative;
            bottom: -1px;
            font-size: 13px;
            margin-right: 8px;
        }
    }

    .overlay__main {
        max-width: 980px;
    }

    .no-results {
        max-width: 360px;
        padding-left: 15px;
        line-height: (30/24);
        font-size: 24px;
        font-weight: 600;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    .newsletter {
        position: relative;
        background: none;
        text-align: left;
        padding-top: 30px;
        margin-top: 30px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 80px;
            height: 1px;
            background: rgba(#000, 0.3);
        }

        &__title {
            margin: 0;
            line-height: (20/16);
            font-size: 1rem;
            color: rgba(#000, 0.5);
        }

        &__form {
            margin: 0;
        }

        .form-control {
            padding-left: 0;
            text-align: left;
            border-bottom-color: rgba(#000, 0.2);

            &::placeholder {
                color: rgba(#000, 0.2);
            }
        }

        .is-full .form-control,
        .form-control:focus {
            border-bottom-color: #fff;
        }

        .form-success {
            margin-top: 30px;
        }

        .form-success__icon {
            float: left;
            margin-right: 20px;
        }
    }
}
