.clock {
	position: relative;
	width: 100%;
	text-align: center;
	@extend .clearfix;

	&__block {
		position: relative;
		display: inline-block;
		width: (100%/3);
		max-width: 80px;

		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 1px;
			width: 1px;
			height: 25px;
			background-color: rgba(0, 0, 0, 0.3);
		}

		&:last-child:after {
			display: none;
		}
	}

	&__label,
	&__value {
		display: block;
		text-align: center;
		font-weight: 600;
	}

	&__value {
		line-height: 1;
		font-size: 33px;
		color: $brand-primary;
	}

	&__label {
		text-transform: uppercase;
		line-height: 16px;
		font-size: 9px;
		color: rgba(#737373, 0.5);
	}

	&--lg {
		.clock__block {
			float: none;
			display: inline-block;
			width: auto;
			padding: 0 15px;

			&:first-child {
				padding-left: 0;
			}
		}

		.clock__value {
			font-size: 44px;
		}
	}
}
