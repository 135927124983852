.message {
    @extend .clearfix;
    width: 100%;
    margin-bottom: 30px;

    &__body {
        max-width: 80%;
        color: #1D1D1D;
        font-size: $font-size-base;
        line-height: (23/16);
    }

    &__footer {
        clear: both;
        padding-top: 7px;
        font-size: 11px;
        line-height: (16/11);
        color: rgba($brand-gray, 0.5);
    }

    &__date {

    }

    &__author {

    }

    &--left {

    }

    &--right {

        .message__body {
            float: right;
            color: $brand-gray;
        }

        .message__footer {
            text-align: right;
        }

        .message__author {
            display: none;
        }
    }
}

.messages {

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li:last-child {
        .message {
            margin-bottom: 10px;
        }
    }
}

/**
 * Message thread in the dashboard sidebar
 */

.messages--thread {
    border-top: 1px solid rgba(#979797, 0.2);

    .messages__messages {
        padding: 30px 40px;
    }

    .messages__reply {
        position: relative;
        border-top: 1px solid rgba(#979797, 0.2);

        textarea {
            padding: 20px 130px 20px 20px;
            border: none;
            border-radius: 0;
        }

        .btn {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
}
