$mfp-z-index-base: 9000;

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}


//.mfp-with-zoom .mfp-container,
//.mfp-with-zoom.mfp-bg {
//  opacity: 0;
//  -webkit-backface-visibility: hidden;
//  /* ideally, transition speed should match zoom duration */
//  -webkit-transition: all 0.3s ease-out;
//  -moz-transition: all 0.3s ease-out;
//  -o-transition: all 0.3s ease-out;
//  transition: all 0.3s ease-out;
//}
//
//.mfp-with-zoom.mfp-ready .mfp-container {
//    opacity: 1;
//}
//.mfp-with-zoom.mfp-ready.mfp-bg {
//    opacity: 0.8;
//}
//
//.mfp-with-zoom.mfp-removing .mfp-container,
//.mfp-with-zoom.mfp-removing.mfp-bg {
//  opacity: 0;
//}
