.author {
    @extend .clearfix;

    .author__media {
        float: left;
        width: 50px;
        height: 50px;
        margin-right: 15px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    .author__main {
        overflow: hidden;
    }

    .author__name {
        color: $brand-gray-dark;
    }

    /**
     * Author tile in the navbar
     */

    &--navbar {

    }

    /**
     * Author view in an idea footer
     */

    &--footer {

        .author__name {
            margin: 2px 0;
            font-size: $font-size-lg;
            line-height: (22/18);
            font-weight: 500;
            color: $brand-gray-dark;

            a {
                color: $brand-gray-dark;
            }
        }

        .author__meta {
            color: $brand-gray;
            font-size: 13px;
            line-height: (21/13);
        }

    }
}
