$icomoon-font-path: "./fonts";

$icon-eye: \e923;
$icon-award: \e91b;
$icon-bell: \e91c;
$icon-cross-small: \e91d;
$icon-envelope: \e91e;
$icon-heart: \e91f;
$icon-tick-small: \e920;
$icon-dot: \e91a;
$icon-pin: \e919;
$icon-cross-thin: \e904;
$icon-envelope-outline: \e905;
$icon-facebook-outline: \e90b;
$icon-user-add-outline: \e918;
$icon-hamburger: \e917;
$icon-arrow-left-circle: \e912;
$icon-arrow-right-circle: \e913;
$icon-arrow-left-sign: \e914;
$icon-arrow-right-sign: \e915;
$icon-smile: \e916;
$icon-checkbox-checked: \e910;
$icon-checkbox: \e911;
$icon-google-plus: \ea8b;
$icon-facebook: \ea90;
$icon-twitter: \ea96;
$icon-vk: \ea98;
$icon-star-half: \e90f;
$icon-arrow-right: \e900;
$icon-chevron-down: \e90e;
$icon-chevron-right: \e921;
$icon-chevron-left: \e922;
$icon-chevron-up: \e901;
$icon-comment: \e902;
$icon-cross: \e903;
$icon-search: \e906;
$icon-select-toggle: \e907;
$icon-star-outline: \e908;
$icon-star: \e909;
$icon-tick: \e90a;
$icon-vote-cross: \e90c;
$icon-vote-tick: \e90d;


@font-face {
  font-family: 'gdicons';
  src:  url('#{$icomoon-font-path}/gdicons.eot?xpcvtd');
  src:  url('#{$icomoon-font-path}/gdicons.eot?xpcvtd#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/gdicons.ttf?xpcvtd') format('truetype'),
    url('#{$icomoon-font-path}/gdicons.woff?xpcvtd') format('woff'),
    url('#{$icomoon-font-path}/gdicons.svg?xpcvtd#gdicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

i, .icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gdicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.icon-eye {
  &:before {
    content: unicode($icon-eye);
  }
}
.icon-award {
  &:before {
    content: unicode($icon-award);
  }
}
.icon-bell {
  &:before {
    content: unicode($icon-bell);
  }
}
.icon-cross-small {
  &:before {
    content: unicode($icon-cross-small);
  }
}
.icon-envelope {
  &:before {
    content: unicode($icon-envelope);
  }
}
.icon-heart {
  &:before {
    content: unicode($icon-heart);
  }
}
.icon-tick-small {
  &:before {
    content: unicode($icon-tick-small);
  }
}
.icon-dot {
  &:before {
    content: unicode($icon-dot);
  }
}
.icon-pin {
  &:before {
    content: unicode($icon-pin);
  }
}
.icon-cross-thin {
  &:before {
    content: unicode($icon-cross-thin);
  }
}
.icon-envelope-outline {
  &:before {
    content: unicode($icon-envelope-outline);
  }
}
.icon-facebook-outline {
  &:before {
    content: unicode($icon-facebook-outline);
  }
}
.icon-user-add-outline {
  &:before {
    content: unicode($icon-user-add-outline);
  }
}
.icon-hamburger {
  &:before {
    content: unicode($icon-hamburger);
  }
}
.icon-arrow-left-circle {
  &:before {
    content: unicode($icon-arrow-left-circle);
  }
}
.icon-arrow-right-circle {
  &:before {
    content: unicode($icon-arrow-right-circle);
  }
}
.icon-arrow-left-sign {
  &:before {
    content: unicode($icon-arrow-left-sign);
  }
}
.icon-arrow-right-sign {
  &:before {
    content: unicode($icon-arrow-right-sign);
  }
}
.icon-smile {
  &:before {
    content: unicode($icon-smile);
  }
}
.icon-checkbox-checked {
  &:before {
    content: unicode($icon-checkbox-checked);
  }
}
.icon-checkbox {
  &:before {
    content: unicode($icon-checkbox);
  }
}
.icon-google-plus {
  &:before {
    content: unicode($icon-google-plus);
  }
}
.icon-facebook {
  &:before {
    content: unicode($icon-facebook);
  }
}
.icon-twitter {
  &:before {
    content: unicode($icon-twitter);
  }
}
.icon-vk {
  &:before {
    content: unicode($icon-vk);
  }
}
.icon-star-half {
  &:before {
    content: unicode($icon-star-half);
  }
}
.icon-arrow-right {
  &:before {
    content: unicode($icon-arrow-right);
  }
}
.icon-chevron-down {
  &:before {
    content: unicode($icon-chevron-down);
  }
}
.icon-chevron-right {
  &:before {
    content: unicode($icon-chevron-right);
  }
}
.icon-chevron-left {
  &:before {
    content: unicode($icon-chevron-left);
  }
}
.icon-chevron-up {
  &:before {
    content: unicode($icon-chevron-up);
  }
}
.icon-comment {
  &:before {
    content: unicode($icon-comment);
  }
}
.icon-cross {
  &:before {
    content: unicode($icon-cross);
  }
}
.icon-search {
  &:before {
    content: unicode($icon-search);
  }
}
.icon-select-toggle {
  &:before {
    content: unicode($icon-select-toggle);
  }
}
.icon-star-outline {
  &:before {
    content: unicode($icon-star-outline);
  }
}
.icon-star {
  &:before {
    content: unicode($icon-star);
  }
}
.icon-tick {
  &:before {
    content: unicode($icon-tick);
  }
}
.icon-vote-cross {
  &:before {
    content: unicode($icon-vote-cross);
  }
}
.icon-vote-tick {
  &:before {
    content: unicode($icon-vote-tick);
  }
}
