// Grid settings
$enable-flex: true;

// Colors
$brand-primary: #2E69FF;
$brand-primary-dark: #2757F0;
$brand-secondary: #1db3bc;
$brand-gray: #737373;
$brand-gray-dark: #1D1D1D;
$brand-gray-light: #BCBCBC;
$body-bg: #F3F3F3;
$body-color: #1E1E1E;
$translucent-white: rgba(255, 255, 255, 0.4);

$lift-color: #6BBA13;
$skip-color:#F5A623;
$sift-color: #D0021B;

$grid-gutter-width-base: 30px;
$font-size-xs: 0.75rem;


// Fonts
$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-serif: $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;

$font-weight-base: 400;
$line-height-base: 1.5;

$headings-font-weight: bold;

$font-size-h1: 48px;
$font-size-h2: 46px;
$font-size-h3: 28px;
$font-size-h4: 24px;

$font-size-lg: 1rem;

// Container sizes

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1060px,
    xl: 1400px
);

$container-max-widths: (
    sm: 96%,
    md: 90%,
    lg: 980px,
    xl: 1310px
);

// forms

$input-color-placeholder: rgba($brand-gray, 0.5);
$custom-select-indicator: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBvbHlnb24gc3R5bGU9ImZpbGw6I0IwQjBCMDsiIGlkPSJTaGFwZS1Db3B5IiBwb2ludHM9IjE2LDAgNi43LDkuMyA4LjksMTEuNSAxNiw0LjMgMjMuMSwxMS41IDI1LjMsOS4zICIvPjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNCMEIwQjA7IiBpZD0iU2hhcGUtQ29weS0yIiBwb2ludHM9IjE2LDMyIDI1LjMsMjIuNyAyMy4xLDIwLjUgMTYsMjcuNyA4LjksMjAuNSA2LjcsMjIuNyAiLz48L2c+PC9zdmc+");
$custom-select-indicator-dark: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBvbHlnb24gc3R5bGU9ImZpbGw6cmdiYSgzMCwgMzAsIDMwLCAwLjcpOyIgaWQ9IlNoYXBlLUNvcHkiIHBvaW50cz0iMTYsMCA2LjcsOS4zIDguOSwxMS41IDE2LDQuMyAyMy4xLDExLjUgMjUuMyw5LjMgIi8+PHBvbHlnb24gc3R5bGU9ImZpbGw6cmdiYSgzMCwgMzAsIDMwLCAwLjcpOyIgaWQ9IlNoYXBlLUNvcHktMiIgcG9pbnRzPSIxNiwzMiAyNS4zLDIyLjcgMjMuMSwyMC41IDE2LDI3LjcgOC45LDIwLjUgNi43LDIyLjcgIi8+PC9nPjwvc3ZnPg==");
