.section {
  padding: 60px 0;

  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }

  &.section--gray {
    background: $body-bg;
    color: $brand-gray-dark;
  }

  &.section--primary {
    background-color: $brand-primary;
    color: #fff;

    a {
      color: #fff;
    }
  }

  &--ideas {
    padding-top: 70px;
    background: #fff;
  }

  &--card-page {
    padding: 0;
    margin-top: -280px;
    margin-bottom: 100px;

    .container {
      max-width: (1130px + $grid-gutter-width-base);
    }
  }

  &--cards {
    padding: 0;
    margin-top: -50px;
    margin-bottom: 100px;

    .card-row {
      @include make-row();
    }

    .card-col-main {
      @include make-col-ready();
      @include make-col(12);
      margin-bottom: 30px;

      @include media-breakpoint-up(lg) {
        @include make-col(12);
        margin-bottom: 0;
      }
    }

    .card-col-sidebar {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(5);
      }

      .card__header__actions {

        @include media-breakpoint-down(xs) {
          position: static;
          margin-top: 3px;
          margin-bottom: 7px;
        }

        @include media-breakpoint-between(md, lg) {
          position: static;
          margin-top: 7px;
        }
      }
    }
  }


  &--content {
    text-align: center;

    .section__title {
      margin: 0 0 20px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: $font-size-base;

      @include media-breakpoint-up(sm) {
        margin-bottom: 25px;
        font-size: $font-size-lg;
      }
    }

    .section__headline {
      max-width: 880px;
      margin: 0 auto 20px;
      color: $brand-primary;
      line-height: (59/46);
      font-size: 26px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 40px;
        font-size: 46px;
      }
    }

    .section__content {
      margin: 0 auto;
      max-width: 660px;
      line-height: (29/18);
      font-weight: 500;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-lg;
      }
    }

    .section__actions {

      @include media-breakpoint-up(sm) {
        margin-top: 50px;
      }

      .btn {
        margin-top: 20px;

        @include media-breakpoint-up(sm) {
          margin-top: 0;
          margin-right: 20px;
        }
      }

      .btn:last-child {
        margin-right: 0;
      }
    }
  }

  &--cta {
    padding: 30px 0 100px;
    background: #fff;

    @include media-breakpoint-up(lg) {
      padding: 60px 0 200px;
    }

    .section__title {
      font-size: 18px;
      line-height: (30/24);
      font-weight: 600;
      text-transform: none;

      @include media-breakpoint-up(lg) {
        font-size: 24px;
      }
    }

    .section__actions {
      margin-top: 35px;
    }
  }

  &--blue {
    background: $brand-primary;
    color: #fff;

    .section__title,
    .section__headline,
    a {
      color: #fff;
    }

    .section__title {
      @extend .hero__title;
      font-weight: bold;
    }

    ol {
      margin: 30px 0;
      padding: 0;
      list-style-type: none;

      li {
        counter-increment: step-counter;
        position: relative;
        padding-top: 3px;
        padding-left: 54px;
        margin-bottom: 35px;
      }

      li:before {
        content: counter(step-counter);
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 34px;
        height: 34px;
        line-height: 36px;
        border-radius: 50%;
        text-align: center;
        font-weight: bold;
        color: #fff;
        background-color: #FF620A;
      }
    }
  }

  &--body-content {
    text-align: left;

    .section__editable {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(8);
        @include make-col-offset(2);
      }
    }

    h1, h2, h3 {
      margin: 60px 0 0;
      line-height: (53/32);
      font-size: 32px;
      font-weight: 500;
      color: $brand-primary;

      &:first-child {
        margin-top: 0;
      }
    }

    h4 {
      margin: 60px 0 0;
      line-height: (29/18);
      font-size: $font-size-lg;
      font-weight: 500;
      color: $brand-gray-dark;
    }

    p {
      margin: 0 0 15px;
      line-height: (29/18);
      font-size: $font-size-lg;
    }

    a:not(.btn) {
      display: inline-block;
      position: relative;
      white-space: nowrap;

      &:after {
        content: '';
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 100%;
        height: 1px;
        background: $brand-primary;
        transition: all 0.2s;
      }

      &:hover {
        text-decoration: none;

        &:after {
          bottom: 1px;
        }
      }
    }
  }

  &--contact-form {
    padding: 0 0 160px;
    background-color: transparent;
    color: #fff;

    .section__main {
      @include media-breakpoint-up(lg) {
        @include make-row();
      }
    }

    .section__sidebar {
      @include media-breakpoint-up(lg) {
        @include make-col-ready();
        @include make-col(3);
        @include make-col-offset(1);
      }
    }

    .section__body {
      // Single col on mobile.
      padding: 30px 20px;
      margin-top: 50px;

      @include media-breakpoint-up(lg) {
        padding: 35px !important;
        @include make-col-ready();
        @include make-col(6);
        @include make-col-offset(1);
      }

    }

    // Centre align the recaptcha panel.
    .form-group--recaptcha .control-wrapper {
      text-align: center;

      .g-recaptcha {
        display: inline-block;
      }
    }


    .section__editable {

      h1, h2, h3 {
        margin-bottom: 15px;
        font-size: 38px;

        @include media-breakpoint-up(sm) {
          font-size: 56px;
        }
      }

      p {
        font-size: $font-size-lg;
      }
    }
  }

  &--legals {
    background: #fff;

    .section__main {
      @include media-breakpoint-up(lg) {
        @include make-row();
      }
    }

    .section__body {
      text-align: left;

      @include media-breakpoint-up(lg) {
        @include make-col-ready();
        @include make-col(10);
        @include make-col-offset(1);
      }
    }

    .section__block {
      position: relative;
      margin-bottom: 40px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
      }

      h1, h2, h3, h4 {
        line-height: (27/19);
        font-size: 19px;
        font-weight: 600;
        color: $brand-primary;

        @include media-breakpoint-up(lg) {
          position: absolute;
          left: 0;
          top: 0;
          max-width: 170px;
        }
      }

      p {
        line-height: (29/18);
        font-size: $font-size-lg;

        @include media-breakpoint-up(lg) {
          padding-left: 230px;
        }
      }
    }
  }

  &--404 {
    padding: 120px 0 120px;
    text-align: left;

    @include media-breakpoint-up(sm) {
      padding: 230px 0 200px;
    }

    .section__main {
      @include media-breakpoint-up(md) {
        @include make-row();
      }
    }

    .section__body {
      text-align: left;

      @include media-breakpoint-up(md) {
        @include make-col-ready();
        @include make-col(10);
        @include make-col-offset(1);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }

    .section__title {
      max-width: 420px;
      margin: 0 0 6px;
      line-height: (21/16);
      text-transform: none;
      font-size: $font-size-base;
      font-weight: 600;
    }

    .section__content {
      max-width: 420px;
      margin: 0;
      line-height: (21/16);
      font-size: $font-size-base;
      font-weight: 400;
    }

    .quote {
      margin-top: 60px;
      color: #fff;
    }
  }
}
