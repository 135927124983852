.favorite {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-shadow;

    &:not(.favorite--active) {
        @extend .btn-white;
    }

    &__active {
        display: none;
    }

    &__inactive {
        display: inline-block;
    }

    &--active {
        @extend .btn-primary;

        .favorite__active {
            display: inline-block;
        }

        .favorite__inactive {
            display: none;
        }
    }
}
