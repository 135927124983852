.card {
    border: none;
    border-radius: 40px;

      @include media-breakpoint-down(sm) {
          border-radius: 20px;
      }

    + .card {
        margin-top: 30px;
    }

    &__header {
        position: relative;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 30px;
        }
    }

    &__title {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        line-height: (40/24);
        color: $brand-primary;
    }

    &__body {

    }

    &--center {
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @media (max-height: 1200px) {
            position: static;
            transform: none;
        }
    }

    &--page {
        box-shadow: 10px 16px 32px rgba(0, 0, 0, 0.2);

        @include media-breakpoint-up(sm) {
            box-shadow: 20px 32px 64px rgba(0, 0, 0, 0.2);
        }
    }

    &--content {

        .card__header {
            padding: 20px 20px 0;

            @include media-breakpoint-up(sm) {
                padding: 30px 40px 0;
            }

            &__actions {
                position: absolute;
                right: 20px;
                bottom: 2px;

                @include media-breakpoint-up(sm) {
                    right: 40px;
                }
            }
        }

        .card__body {
            padding: 0 20px 60px;

            @include media-breakpoint-up(sm) {
                padding: 0 40px 60px;
            }

            &:last-child {
                padding-bottom: 60px;

                @include media-breakpoint-up(sm) {
                    padding-bottom: 120px;
                }
            }
        }
    }

    &--compose,
    &--thread {
        .card__title {
            text-align: center;
        }

        .card__back {
            position: absolute;
            top: 5px;
            left: 10px;
            padding: 20px;
            color: rgba($brand-gray, 0.5);
            transition: all 0.3s;

            @include media-breakpoint-up(sm) {
                top: 20px;
                left: 20px;
            }

            &:hover {
                color: $brand-gray;
                text-decoration: none;
            }
        }
    }

    &--thread {
        .messages__messages {
            @include media-breakpoint-up(lg) {
                max-height: 500px;
                overflow: auto;
            }
        }
    }

    &--edit-profile {
        .instructions {
            line-height: (24/16);
            color: #737373;

            @include media-breakpoint-up(lg) {
                padding-right: 60px;
            }
        }
    }

    &--auth {
        width: 100%;
        max-width: 500px;
        box-shadow: 0 32px 64px rgba(0, 0, 0, 0.34);

        @include media-breakpoint-up(lg) {
            max-width: 660px;
        }

        .card__inner {
            @extend .clearfix;
        }

        .card__sidebar {
            padding: 60px 40px 45px;

            @include media-breakpoint-up(lg) {
                float: left;
                width: 45%;
                padding: 65px 60px 40px;
            }

            p {
                margin-top: 18px;
                margin-bottom: 0;
                color: $brand-gray;
                line-height: (19/13);
                font-weight: 500;
                font-size: 13px;
            }
        }

        .message-container {
            margin-top: 20px;
        }

        .card__main {
            background: $body-bg;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            @include media-breakpoint-up(md) {
                border-bottom-left-radius: 40px;
                border-bottom-right-radius: 40px;
            }

            @include media-breakpoint-up(lg) {
                border-bottom-left-radius: 0;
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                float: left;
                width: 55%;
            }


        }


        .form {
            padding-top: 35px;
        }

        .form-group {
            padding-left: 40px;
            padding-right: 40px;
        }

        .form-group--country {
            margin-bottom: 30px;
        }

        .alert {
            margin-left: 40px;
            margin-right: 40px;
            margin-bottom: 10px;
        }

        .card__menu {
            list-style: none;
            margin: 0;
            padding: 0;

            a {
                display: block;
                line-height: 115px; // Reduce this value when adding back SSO logins.
                padding: 0 40px;
                font-weight: 600;
                color: $brand-gray;
                transition: all 0.3s;

                @include media-breakpoint-up(lg) {
                    padding: 0 50px;
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.01);
                    color: $brand-primary;
                }

                &:focus, &:active, &:hover {
                    text-decoration: none;
                }
            }

            li:not(:last-child) a {
                border-bottom: 1px solid rgba(#1D1D1D, 0.3);
            }


            i {
                margin-right: 16px;
                font-size: 29px;
                vertical-align: text-bottom;
            }
        }

        .facebook-login {
            a {
                color: $brand-primary;
            }

            span {
                position: relative;
                bottom: -2px;
            }
        }
    }
}

.card--unsubscribe,
.card--forgot-password {
    .form--auth {
        @include media-breakpoint-up(lg) {
            padding-top: 90px;
        }
    }

    .form-footer {
        @include media-breakpoint-up(lg) {
            margin-top: 90px;
        }
    }
}
