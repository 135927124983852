.rating {
    @extend .clearfix;
    position: relative;
    bottom: -3px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        float: left;
    }

    a, span {
        display: block;
        padding-right: 5px;
        color: $brand-primary;
        transition: all 0.2s;
    }

    a {
        &:hover, &:focus, &:active {
            text-decoration: none;
            transform: scale(1.1);
        }
    }

    i {
        display: block;
        @extend .icon-font;

        &:before {
            content: unicode($icon-star-outline);
        }
    }

    .on {
        a {
            color: $brand-secondary;
        }
    }

    .selected-half i:before {
        content: unicode($icon-star-half);
    }

    .selected i:before {
        content: unicode($icon-star);
    }

    /**
     * State when the user is hovered over the rating bar
     */

    &--active {
        a, .on a {
            color: $brand-primary;
        }

        .selected,
        .selected-half {
            i:before {
                content: unicode($icon-star-outline);
            }
        }

        .highlighted i:before {
            content: unicode($icon-star);
            color: $brand-primary;
        }
    }
}
