$header-outer-height: 108px;

.header {
    @extend .clearfix;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 25px 0;
    font-weight: 500;
    z-index: 100;

    //background-color: rgb(6, 58, 144);
    //border-bottom: 20px solid white;

    @include media-breakpoint-up(md) {
        padding: 35px 0;
    }

    &__logo {
        float: left;
        width: 110px;

        @include media-breakpoint-up(md) {
            width: auto;
        }

        img {
            max-width: 100%;
        }
    }

    &__nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 100px 70px;
        background: $brand-primary;
        opacity: 0;
        visibility: hidden;
        transition: all 0.35s;

        @include media-breakpoint-up(md) {
            display: block;
            position: static;
            float: right;
            padding: 0;
            background-color: transparent;
            opacity: 1;
            visibility: visible;
            transform: none;
        }

        .nav {

            @include media-breakpoint-up(md) {

            }
        }

        .nav--secondary {
            margin-top: 50px;
        }
    }

    &__toggle {
        position: absolute;
        right: 10px;
        top: 5px;
        padding: 10px;
        line-height: 1;
        font-size: 20px;
        width: 40px;
        height: 40px;
        color: #fff;
        cursor: pointer;

        &:before,
        &:after {
            @extend .icon-font;
            display: block;
            position: absolute;
            top: 10px;
            transition: all 0.3s;
        }

        &:before {
            content: unicode($icon-hamburger);
        }

        &:after {
            content: unicode($icon-cross-thin);
            font-size: 18px;
            opacity: 0;
            transform: scale(0.5) rotateZ(-90deg);
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .btn--login {
        @extend .btn;
        @extend .btn-white;
        @extend .btn-sm;
        @extend .btn-outline;

        margin-top: -3px;
        margin-left: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: normal;
        font-size: 14px;
        font-weight: 600;
        border-width: 2px;
        border-color: rgba(255, 255, 255, 0.8);

        span {
            display: inline-block;
            position: relative;
            top: -1px;
            font-size: 10px;
        }
    }

    &--nav-open {
        .header__nav {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
        }

        .header__toggle {
            &:before {
                opacity: 0;
                transform: scale(0.3) rotateZ(90deg);
            }

            &:after {
                opacity: 1;
                transform: scale(1) rotateZ(0deg);
            }
        }
    }
}
