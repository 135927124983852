

.primary-heading {
  color: $brand-primary;
}

.inverted-header-bar {
  background-color: $brand-primary;
  padding: 20px 10px;

  h1,h2,h3,h4,h5,h6 {
    color: white;
    margin: 0;
  }

}


