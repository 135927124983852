.profile {
    @extend .clearfix;

    .profile__photo {
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

        img {
            width: 100%;
            height: auto;
        }
    }

    &__actions {
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
            position: absolute;
            right: 0;
            bottom: -30px;
            margin-top: 0;
            transform: translate(0, 50%);
        }

        .btn {
            margin-left: 10px;
        }
    }

    &__main {
        padding: 40px 30px 50px;
        background: #fff;

        @include media-breakpoint-up(lg) {
            margin: 0 40px 150px;
            padding: 50px 40px;
        }

        &__inner {
            max-width: 680px;
            margin: 0 auto;
        }
    }

    &__ideas {
        clear: both;
        margin-bottom: 35px;
    }

    &__rating_summary {
        margin-bottom: 40px;

        .rating {
            font-size: 20px;
        }
    }

    &__rating_title {
        max-width: 260px;
        margin-bottom: 12px;
        line-height: (21/18);
        font-size: $font-size-lg;
        color: $brand-gray-dark;
    }

    &__follows {
        clear: both;
        margin-bottom: 35px;

        &__title {
            margin: 0 0 30px;
            line-height: (21/18);
            font-size: $font-size-lg;
            color: $brand-gray-dark;
        }

        .num {
            font-size: 13px;
            color: $brand-primary;
        }

        &__empty {
            color: $brand-gray;
        }
    }

    &__profiles {

    }

    &--details {
        background: #F3F3F3;

        .profile__header {
            position: relative;
            margin: 40px 30px;
            text-align: center;

            @include media-breakpoint-up(lg) {
                margin: 70px 80px 30px;
                text-align: left;
            }
        }

        .profile__photo {
            margin: 0 auto 20px;
            width: 100px;
            height: 100px;

            @include media-breakpoint-up(lg) {
                float: left;
                margin: 0 35px 0 0;
            }
        }

        .profile__wrap {
            overflow: hidden;
        }

        .profile__name {
            margin: 10px 0 6px;
            line-height: (46/36);
            font-size: 36px;
            color: $brand-primary;
        }

        .profile__meta {
            font-size: $font-size-base;
            color: $brand-gray;

            a {
                color: $brand-gray;
            }
        }

        .flag-icon {
            position: relative;
            top: -1px;
            margin-right: 3px;
        }

        .profile__stats {
            display: none;
            line-height: (21/14);
            color: $brand-gray;
            font-size: 14px;

            @include media-breakpoint-up(lg) {
                position: absolute;
                right: 0;
                bottom: 0;
                text-align: right;
            }
        }

        .profile__bio {
            max-width: 420px;
            margin-top: 12px;
            font-size: 13px;
        }
    }

    &--tile {
        margin-bottom: 12px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 24px;
        }

        .profile__photo {
            float: left;
            margin-right: 0;
            width: 40px;
            height: 40px;

            @include media-breakpoint-up(lg) {
                margin-right: 0;
            }
        }

        .profile__header {
            margin: 0 0 0 52px;
            background-color: transparent;
            text-align: left;
        }

        .profile__name {
            margin: 1px 0 0 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &, & a {
                color: $brand-gray-dark;
                font-size: $font-size-base;
            }
        }

        .author__region {
            position: relative;
            top: -3px;
            margin: 0;
            line-height: 1;
            color: $brand-gray;
            font-size: 13px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.profiles--grid {
    @extend .clearfix;

    .profile {
        padding-right: 20px;

        @include media-breakpoint-up(sm) {
            float: left;
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            width: 33%;
        }
    }
}
